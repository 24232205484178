import PropTypes from "prop-types";
import React from "react";
import Column from "./Column";

const ColumnSpacer = ({ space }) => <Column flex={1} space={space} />;

ColumnSpacer.propTypes = {
  space: PropTypes.number,
};

ColumnSpacer.defaultProps = {
  space: null,
};

export default ColumnSpacer;
