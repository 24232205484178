import React from "react";
import { ThemeContext } from "styled-components";

/**
 * A React hook providing the Styled Components theme. Useful for accessing
 * theme values outside of Styled Components.
 */
const useTheme = () => {
  return React.useContext(ThemeContext);
};

export { useTheme };
