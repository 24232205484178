import { getHashFragment, isHash, linkResolver } from "@dangerfarms/prismic";
import {
  CodeBlock,
  Link,
  Stack,
  Text,
  Highlight,
  ListItem,
  UnorderedList,
} from "@dangerfarms/ui-system";
import { Elements, RichText } from "prismic-reactjs";
import PrismicRichText from "prismic-richtext";
import PropTypes from "prop-types";
import React from "react";
import slugify from "./slugify";

// Given a rich text node, htmlSerializer decides which element to render.
// (The default is <span>, which is triggered by returning `null`.)
const htmlSerializer = ({ withHighlights, withAnchors, ...props }) => (
  type,
  element,
  content,
  children,
  key,
) => {
  // Check if this rich text node is at the root level of the tree representation.
  //
  // If it is a root node, pass the parent props along. This gives a nice API
  // where you can pass props, eg. <RichText variant="lede" /> to use a lede
  // style for the underlying <Text /> elements.
  const isRoot = content === null;
  const childProps = isRoot ? props : {};

  switch (type) {
    case Elements.heading1:
      return (
        <Text
          key={key}
          as="h1"
          id={withAnchors ? slugify(element.text) : null}
          variant="heading"
          {...childProps}
        >
          {children}
        </Text>
      );
    case Elements.heading2:
      return (
        <Text
          key={key}
          as="h2"
          id={withAnchors ? slugify(element.text) : null}
          variant="subheading"
          {...childProps}
        >
          {children}
        </Text>
      );
    case Elements.heading3: {
      return (
        <Text
          key={key}
          as="h3"
          id={withAnchors ? slugify(element.text) : null}
          variant="subheading"
          {...childProps}
        >
          {children}
        </Text>
      );
    }
    case Elements.paragraph:
      return (
        <Text key={key} {...childProps}>
          {children}
        </Text>
      );
    case Elements.em:
      return (
        <Text key={key} variant="italic" {...childProps}>
          {children}
        </Text>
      );
    case Elements.strong:
      if (withHighlights) {
        return (
          <Highlight key={key} {...childProps}>
            {children}
          </Highlight>
        );
      } else {
        return (
          <Text key={key} as="span" weight="bold">
            {children}
          </Text>
        );
      }
    case Elements.preformatted:
      return (
        <CodeBlock key={key} {...childProps}>
          {children}
        </CodeBlock>
      );
    case Elements.listItem:
      return (
        <ListItem key={key} {...childProps}>
          {children}
        </ListItem>
      );
    case Elements.list:
      return (
        <UnorderedList key={key} {...childProps}>
          {children}
        </UnorderedList>
      );
    case Elements.hyperlink: {
      const link = element.data;
      if (link.link_type === "Document") {
        return (
          <Link key={key} to={linkResolver(link)} {...childProps}>
            {children}
          </Link>
        );
      } else if (isHash(link)) {
        return (
          <Link key={key} href={getHashFragment(link)} {...childProps}>
            {children}
          </Link>
        );
      } else {
        return (
          <Link key={key} href={link.url} {...childProps}>
            {children}
          </Link>
        );
      }
    }
    default:
      return null;
  }
};

/**
 * A component that renders raw rich text data from Prismic into components from
 * our design system.
 *
 * See the htmlSerializer function for details on the mapping of rich text to
 * components.
 */
const PrismicText = ({ richText, space, ...props }) => {
  const elementTree = PrismicRichText.asTree(richText);
  const renderedElements = React.Children.toArray(
    RichText.render(richText, null, htmlSerializer(props)).props.children,
  );

  // Group the children. Every time we find a heading, start a new group.
  // This structure makes it possible to use different <Stack> spacing between
  // those "groups" and the paragraphs themselves.
  const children = [];
  renderedElements.forEach((renderedElement, i) => {
    const node = elementTree.children[i];

    // TODO: Consider if this is the best idea. Goal is to preserve correct
    //       spacing, even if extra newlines are added by content creator.
    //       But content creators may actually want to use newlines to create
    //       extra space...
    if (node.element.type === Elements.paragraph && node.element.text === "") {
      return;
    }

    // Headings trigger a new group, or if children is empty, we'll need a new
    // group to kick things off.
    if (
      [Elements.heading1, Elements.heading2, Elements.heading3].includes(
        node.type,
      ) ||
      children.length === 0
    ) {
      children.push([renderedElement]);
    } else {
      // Usually the next element is appended to the last group.
      children[children.length - 1].push(renderedElement);
    }
  });

  // We can simplify the output for simpler rich text data, so let's do that.
  // First, check if there's only one group of text.
  if (children.length === 1) {
    // Then, we'll do something different depending on the size of the lone group.
    if (children[0].length === 1) {
      // If there's only one child in that one group, return it directly, no
      // need for Stacks.
      return children[0][0];
    } else {
      // When there's only one group, but it has multiple children (eg. multiple
      // paragraphs but no headings), wrap the output in a single stack.
      return <Stack space={space}>{children[0]}</Stack>;
    }
  } else {
    // This is the more complex case, where there are multiple groups. In this
    // case, each group is a Stack child, then the group's children are a nested
    // Stack. The outer stack has more space than the inner one.
    return (
      <Stack space={space * 2}>
        {children.map((child, i) => (
          // There's no better key than the array index here, and Prismic text
          // should never change at runtime anyway (it's sourced from static
          // data).
          //
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={i} space={space}>
            {child}
          </Stack>
        ))}
      </Stack>
    );
  }
};

PrismicText.propTypes = {
  // No point in validating the richText prop in depth: it's very tricky, and
  // it comes from a GraphQL query in practice anyway (ie. devs don't need to
  // manually create it).
  //
  // eslint-disable-next-line react/forbid-prop-types
  richText: PropTypes.array.isRequired,

  // Allow tweaking of paragraph spacing
  space: PropTypes.number,

  // Set to true to autogenerate `id` attributes for each header
  withAnchors: PropTypes.bool,

  // Set to true to wrap <strong> spans with a <Highlight /> component instead.
  //
  // Nice for landing page text, not so good for longform content like blog posts
  // and policy documents.
  withHighlights: PropTypes.bool,
};

PrismicText.defaultProps = {
  space: 1,
  withAnchors: false,
  withHighlights: false,
};

export default PrismicText;
