import { system } from "styled-system";

/**
 * Tweaks the styled-system typography props so their names don't clash with
 * DOM or SVG attributes.
 */
const config = {
  textSize: {
    property: "fontSize",
    scale: "fontSizes",
  },
  lineHeight: {
    property: "lineHeight",
    scale: "lineHeights",
  },
  charSpacing: {
    property: "letterSpacing",
    scale: "letterSpacings",
  },
  fontFamily: true,
  textAlign: true,
  textTransform: true,
  fontStyle: true,
  weight: {
    property: "fontWeight",
    scale: "fontWeights",
  },
};

export const typography = system(config);
export default typography;
