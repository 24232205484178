import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion as ReachAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import css from "@styled-system/css";
import styled from "styled-components";
import Box from "./Box";
import ColorModeProvider from "./ColorModeProvider";
import ContentBlock from "./ContentBlock";
import OpenIcon from "./icons/OpenIcon";
import Stack from "./Stack";
import Text from "./Text";

const FullWidthAccordion = styled(ReachAccordion)`
  width: 100%;

  & [items-reach-accordion-button][disabled] {
    cursor: not-allowed;
  }
`;

const FullWidthAccordionItem = styled(AccordionItem)`
  width: 100%;
`;

const FullWidthAccordionButton = styled(AccordionButton)`
  width: 100%;
  text-align: left;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const CollapsedAccordionItem = styled(Box)`
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
`;

// Media queries pad content with max width equal to content width as
// viewport width converges with content width. This avoids text butting
// right up against the lateral edges of the viewport.
const ExpandedAccordionItem = styled(Box)`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  box-shadow: inset 0 3px 9px 0 rgba(0, 0, 0, 0.25);
`;

const CloseAccordionIcon = styled.div`
  align-self: center;
  width: 25px;
  margin-right: 7px;
  ${css(theme => ({
    borderTop: `solid 1px ${theme.colors.accent}`,
  }))}
`;

const CentredText = styled(Text)`
  align-self: center;
`;

const Title = props => (
  <CentredText
    color="accent"
    maxWidth="calc(100% - 50px)"
    variant="lede"
    weight="news"
    {...props}
  />
);

const Accordion = ({ items }) => {
  const [expandedAccordionItems, setExpandedAccordionItems] = useState([]);

  const toggleExpandedAccordionItems = value =>
    expandedAccordionItems.includes(value)
      ? setExpandedAccordionItems(
          expandedAccordionItems.filter(item => item !== value),
        )
      : setExpandedAccordionItems([...expandedAccordionItems, value].sort());

  return (
    <FullWidthAccordion
      index={expandedAccordionItems}
      onChange={value => toggleExpandedAccordionItems(value)}
    >
      <Stack boxWidth={1} marginY={2} space={0.5}>
        {items.map((item, index) => {
          const itemIsExpanded = expandedAccordionItems.includes(index);
          return (
            <FullWidthAccordionItem key={item.title}>
              {!itemIsExpanded && (
                <FullWidthAccordionButton>
                  <CollapsedAccordionItem
                    bg="bg"
                    boxDisplay="flex"
                    boxWidth={1}
                    justifyContent="space-between"
                    paddingX="20px"
                    paddingY="20px"
                  >
                    <Title>{item.title}</Title>
                    <OpenIcon />
                  </CollapsedAccordionItem>
                </FullWidthAccordionButton>
              )}
              <AccordionPanel>
                <ColorModeProvider mode="accent">
                  <ExpandedAccordionItem
                    bg="bg"
                    boxWidth="100vw"
                    color="fg"
                    paddingX="gutter"
                  >
                    <ContentBlock paddingBottom="40px" paddingX="20px">
                      <Stack>
                        {itemIsExpanded && (
                          <FullWidthAccordionButton>
                            <Box
                              boxDisplay="flex"
                              boxHeight="80px"
                              boxWidth={1}
                              justifyContent="space-between"
                              paddingY="20px"
                            >
                              <Title>{item.title}</Title>
                              <CloseAccordionIcon />
                            </Box>
                          </FullWidthAccordionButton>
                        )}
                        <Text variant="lede">{item.lede}</Text>
                        {item.renderContent()}
                      </Stack>
                    </ContentBlock>
                  </ExpandedAccordionItem>
                </ColorModeProvider>
              </AccordionPanel>
            </FullWidthAccordionItem>
          );
        })}
      </Stack>
    </FullWidthAccordion>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      lede: PropTypes.string.isRequired,
      renderContent: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Accordion;
