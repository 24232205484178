import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import PropTypes from "prop-types";

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const EmbeddedVideo = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const ResponsivePlayer = ({ embedUrl }) => (
  <PlayerWrapper>
    <EmbeddedVideo
      className="react-player"
      height="100%"
      url={embedUrl}
      width="100%"
    />
  </PlayerWrapper>
);

ResponsivePlayer.propTypes = {
  embedUrl: PropTypes.string.isRequired,
};

export default ResponsivePlayer;
