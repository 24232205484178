import PropTypes from "prop-types";
import React from "react";

const ExternalLink = ({ children, ...props }) => {
  return (
    <a rel="noopener noreferrer" target="_blank" {...props}>
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalLink;
