import css from "@styled-system/css";
import PropTypes from "prop-types";
import styled from "styled-components";
import { align } from "./styled-system";
import Box from "./Box";

const getSpace = ({ theme, space }) => {
  if (typeof space === "string") {
    return { marginTop: space };
  } else if (Array.isArray(space)) {
    return css({
      marginTop: space.map(s => theme.rhythm(s)),
    })({ theme });
  } else {
    return { marginTop: theme.rhythm(space) };
  }
};

// The CSS for the child spacing is a little tricky. The margin-top value uses
// the space prop directly if it's a string, such as "1px". Otherwise, assume
// space is a number, and feed it through the rhythm function.
const Stack = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${align}

  & > :not(:first-child) {
    ${getSpace}
  }
`;

Stack.propTypes = {
  align: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
};

Stack.defaultProps = {
  align: "flex-start",
  space: 1,
};

export default Stack;
