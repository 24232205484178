import css from "@styled-system/css";
import styled from "styled-components";

const Highlight = styled.span`
  ${css({
    color: "accent",
    fontWeight: "bold",
  })}
`;

export default Highlight;
