import css from "@styled-system/css";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

const getAttrs = ({ theme, to, ...props }) => {
  if (to) {
    return { ...props, as: GatsbyLink };
  }

  return props;
};

const Link = styled.a.attrs(getAttrs)`
  overflow-wrap: break-word;
  text-decoration: none;
  word-wrap: break-word;
  word-break: break-word;

  ${css({
    color: "accent",
    fontWeight: "news",
  })}

  &:visited {
    ${css({
      color: "accent",
    })}
  }
`;

export default Link;
