import css from "@styled-system/css";
import PropTypes from "prop-types";
import styled, { css as styledCss } from "styled-components";
import { color } from "styled-system";

const Button = styled.button`
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 0.7rem;
  letter-spacing: 0.09em;
  line-height: 1;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  ${css({
    bg: "fg",
    boxShadow: "large",
    color: "bg",
    fontFamily: "standard",
    fontWeight: "news",
  })}

  ${({ variant }) =>
    variant === "back" &&
    styledCss`
      padding: 16px 20px;

      &::before {
        content: "←";

        ${css({
          marginRight: 2,
        })}
      }
    `}

  ${color}
`;

Button.propTypes = {
  onClick: PropTypes.func,
};

export default Button;
