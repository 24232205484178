/**
 * Redirects Prismic Previews to the correct page.
 * See https://prismic.io/docs/reactjs/getting-started/prismic-gatsby
 *
 * Generally to stick to reading `type` and `uid` fields of the doc variable to
 * figure out where to redirect to. Some of the other fields aren't guaranteed
 * to be there, eg. when querying data from a Link field directly via GraphQL.
 */
export default function dfLinkResolver(doc) {
  if (doc.isBroken) {
    return "/404";
  }

  switch (doc.uid) {
    case "home":
      return "/";
    case "approach":
      return "/approach";
    case "case-studies":
      return "/case-studies";
    case "cookies":
      return "/cookies";
    case "privacy":
      return "/privacy";
    default:
      return "/404";
  }
}
