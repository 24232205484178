import css from "@styled-system/css";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Stack from "./Stack";

const BackgroundSVG = styled.div`
  background-image: ${props => `url(${props.background})`};
  background-size: cover;
  ${({ backgroundSize }) =>
    css({
      width: [parseInt(backgroundSize, 10) * 0.86, backgroundSize],
      height: [parseInt(backgroundSize, 10) * 0.86, backgroundSize],
    })}
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Infographic = ({ background, backgroundSize, children, space }) => (
  <BackgroundSVG background={background} backgroundSize={backgroundSize}>
    <Stack align="center" space={space}>
      {children}
    </Stack>
  </BackgroundSVG>
);

Infographic.propTypes = {
  background: PropTypes.string.isRequired,
  backgroundSize: PropTypes.string,
  children: PropTypes.node.isRequired,
  space: PropTypes.number,
};

Infographic.defaultProps = {
  backgroundSize: "350px",
  space: 0.75,
};

export default Infographic;
