import React from "react";
import { useTheme } from "@dangerfarms/ui-system";
import Svg from "@dangerfarms/ui-system/Svg";

const HamburgerIcon = ({ ...props }) => {
  const theme = useTheme();

  return (
    <Svg height={18} viewBox="0 0 25 20" width={20} {...props}>
      <path
        d="M0 0v2h25V0H0zm0 9v2h25V9H0zm0 9v2h25v-2H0z"
        fill={theme.colors.fg}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default HamburgerIcon;
