/**
 * Stolen from https://gist.github.com/codeguy/6684588#gistcomment-2690429
 */
const slugify = str => {
  // Trim whitespace and lowercase the string.
  let slug = str.replace(/^\s+|\s+$/g, "");
  slug = slug.toLowerCase();

  // Remove accents, swap ñ for n, etc
  const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  slug = slug
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, ""); // trim - from end of text

  return slug;
};

export default slugify;
