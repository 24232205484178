import PropTypes from "prop-types";

const prismicPropTypes = {
  richText: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.any,
      spans: PropTypes.arrayOf(
        PropTypes.shape({
          end: PropTypes.number.isRequired,
          start: PropTypes.number.isRequired,
          type: PropTypes.string.isRequired,
        }),
      ),
      text: PropTypes.string,
      type: PropTypes.string.isRequired,
    }),
  ),
};

export default prismicPropTypes;
