import React from "react";
import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "./useTheme";

const Logo = props => {
  const theme = useTheme();

  return (
    <Svg height={50} viewBox="0 0 223 61" width={180} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M63.523 43.12c4.242 0 7.308-3.276 7.308-7.455s-3.066-7.455-7.308-7.455H58v14.91h5.523zm0-3.36H61.57v-8.19h1.953c1.974 0 3.738 1.197 3.738 4.095 0 2.898-1.764 4.095-3.738 4.095zm11.249 3.36l1.008-2.793h5.418l1.008 2.793h3.696l-5.376-14.91h-4.074l-5.376 14.91h3.696zm5.208-6.153h-2.982l1.491-4.158 1.491 4.158zm11.417 6.153v-8.967l6.447 8.967h3.57V28.21h-3.57v8.967l-6.447-8.967h-3.57v14.91h3.57zm19.775.21c4.452 0 7.623-3.003 7.623-8.862h-8.568v3.36h4.578c-.609 1.365-1.869 2.142-3.633 2.142-2.352 0-3.843-1.848-3.843-4.305s1.701-4.305 4.263-4.305c1.512 0 2.541.84 3.129 1.68l2.688-2.058C116.023 29.176 114.07 28 111.592 28c-4.662 0-7.833 3.486-7.833 7.665s3.171 7.665 7.413 7.665zm18.368-.21v-3.36h-5.25v-2.436h4.2v-3.36h-4.2V31.57h5.25v-3.36h-8.82v14.91h8.82zm5.915 0v-4.284h1.764l2.478 4.284h4.011l-2.898-5.019c1.575-.861 2.688-2.457 2.688-4.578 0-3.318-2.73-5.313-5.628-5.313h-5.985v14.91h3.57zm2.415-7.644h-2.415V31.57h2.415c1.176 0 2.058.735 2.058 1.953s-.882 1.953-2.058 1.953zm18.298 7.644v-5.586h4.62v-3.36h-4.62V31.57h5.25v-3.36h-8.82v14.91h3.57zm8.981 0l1.008-2.793h5.418l1.008 2.793h3.696l-5.376-14.91h-4.074l-5.376 14.91h3.696zm5.208-6.153h-2.982l1.491-4.158 1.491 4.158zm11.207 6.153v-4.284h1.764l2.478 4.284h4.011l-2.898-5.019c1.575-.861 2.688-2.457 2.688-4.578 0-3.318-2.73-5.313-5.628-5.313h-5.985v14.91h3.57zm2.415-7.644h-2.415V31.57h2.415c1.176 0 2.058.735 2.058 1.953s-.882 1.953-2.058 1.953zm12.593 7.644v-8.589l3.906 6.153h1.302l3.906-6.153v8.589h3.57V28.21h-3.57l-4.557 6.993-4.557-6.993h-3.57v14.91h3.57zm20.804.21c2.604 0 4.935-1.428 4.935-4.137 0-2.205-1.512-3.507-4.599-5.292-1.386-.798-1.806-1.113-1.806-1.722 0-.525.483-.819 1.05-.819.819 0 1.365.567 1.365 1.785h3.57c0-2.877-1.911-5.145-4.935-5.145-2.604 0-4.62 1.617-4.62 4.011 0 1.722.567 2.94 4.053 5.061 1.554.945 2.352 1.26 2.352 1.974 0 .462-.42.924-1.365.924-1.008 0-1.785-.588-1.785-1.827h-3.57c0 2.436 1.701 5.187 5.355 5.187z"
          fill={theme.colors.fg}
          fillRule="nonzero"
        />
        <path
          d="M37.5 45L30 60H0l7.5-15h30zM0 0h30l15 30-7.5 15-15-30h-15L0 0z"
          fill={theme.colors.accent}
        />
      </g>
    </Svg>
  );
};

export default Logo;
