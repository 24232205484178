import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider } from "styled-components";
import Fonts from "./Fonts";
import GlobalStyles from "./GlobalStyles";
import { theme } from "./theme";

/**
 * Sets up everything needed to use the DF design system: the theme, global
 * CSS and fonts.
 */
const DfThemeProvider = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={theme} {...props}>
      <GlobalStyles />
      <Fonts />
      {children}
    </ThemeProvider>
  );
};

DfThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DfThemeProvider;
