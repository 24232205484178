module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"dangerfarmscom","previews":true,"omitPrismicScript":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Danger Farms","short_name":"Danger Farms","start_url":"/","background_color":"white","theme_color":"black","display":"minimal-ui","icon":"src/images/favicon.png","icons":[{"src":"/favicons/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
