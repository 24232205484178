import React from "react";
import PropTypes from "prop-types";
import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";

const SendIcon = ({ size, ...props }) => {
  const theme = useTheme();

  return (
    <Svg
      alt="Send icon"
      height={size}
      id="send-icon"
      viewBox="0 0 70 70"
      width={size}
      {...props}
    >
      <defs>
        <path d="M0 0h70v70H0z" id="send-icon-prefix__b" />
        <path
          d="M39.244.133a.701.701 0 00-.66-.087L1.398 9.102c-.704.175-1.231.704-1.364 1.406-.132.704.133 1.406.704 1.89l9.319 6.33.879 11.295c0 .045 0 .088.044.133 0 .044 0 .044.045.087a.605.605 0 00.132.175v.045c.045.045.133.132.22.132.045 0 .045 0 .088.045.044 0 .087.044.132.044h.087c.088 0 .176 0 .22-.044.045 0 .045-.045.045-.045.045-.045.087-.045.132-.087h.045l6.286-5.98 8.132 5.45s.044 0 .044.045c.265.175.572.22.924.22.22 0 .44-.045.66-.132a1.71 1.71 0 001.098-1.1L39.6.926c-.003-.263-.093-.615-.355-.792zm-25.362 20.31a.537.537 0 00-.22.439s0 .045-.045.045l-1.583 5.32-.527-7.209L29.704 7.39 13.882 20.442zM1.354 10.814c0-.087.088-.307.352-.395l33.32-8.087L10.98 17.716l-9.45-6.417c-.22-.177-.176-.396-.176-.484zm11.649 16.88l1.713-5.627 2.462 1.671-4.175 3.956zm14.9.835c-.088.175-.22.264-.308.264-.087.045-.22.045-.395 0l-11.735-7.912L37.31 2.86l-9.407 25.67z"
          id="send-icon-prefix__c"
        />
        <filter
          filterUnits="objectBoundingBox"
          height="160%"
          id="send-icon-prefix__a"
          width="160%"
          x="-25.7%"
          y="-21.4%"
        >
          <feOffset
            dx="3"
            dy="6"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="6"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use
          fill="#000"
          filter="url(#send-icon-prefix__a)"
          xlinkHref="#send-icon-prefix__b"
        />
        <use fill="#FF78C3" xlinkHref="#send-icon-prefix__b" />
        <path d="M0 0h70v70H0z" fill={theme.colors.bg} />
        <g transform="translate(15 20)">
          <mask fill={theme.colors.fg} id="send-icon-prefix__d">
            <use xlinkHref="#send-icon-prefix__c" />
          </mask>
          <use
            fill="#000"
            fillRule="nonzero"
            xlinkHref="#send-icon-prefix__c"
          />
          <g mask="url(#send-icon-prefix__d)">
            <path d="M0-5h40v40H0z" fill={theme.colors.fg} />
          </g>
        </g>
      </g>
    </Svg>
  );
};

SendIcon.propTypes = {
  size: PropTypes.number,
};

SendIcon.defaultProps = {
  size: 25,
};

export default SendIcon;
