import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";
import React from "react";

const SvgFacebook = props => {
  const theme = useTheme();

  return (
    <Svg height="250" viewBox="0 0 250 250" width="250" {...props}>
      <path
        className="hoverable"
        d="M236.2 0H13.8C6.178 0 0 6.178 0 13.8v222.4c0 7.622 6.178 13.8 13.8 13.8h119.74v-96.68h-32.47v-37.84h32.47V87.65c0-32.29 19.72-49.87 48.53-49.87a266.55 266.55 0 0129.11 1.49V73h-19.92c-15.63 0-18.65 7.44-18.65 18.37v24.1h37.47l-4.88 37.84h-32.59V250h63.59c7.622 0 13.8-6.178 13.8-13.8V13.8C250 6.178 243.822 0 236.2 0z"
        fill={theme.colors.fg}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default SvgFacebook;
