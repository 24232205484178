import Input from "@dangerfarms/ui-system/Input";
import Label from "@dangerfarms/ui-system/Label";
import TextArea from "@dangerfarms/ui-system/TextArea";
import PropTypes from "prop-types";
import React from "react";

const TextField = ({ id, label, multiline, ...props }) => {
  const InputComponent = multiline ? TextArea : Input;

  return (
    <Label htmlFor={id} label={label}>
      <InputComponent id={id} {...props} />
    </Label>
  );
};

TextField.propTypes = {
  /**
   * A string that uniquely identifies the input within the page.
   * This is required to link the `<label>` to the `<input>` with proper accessibility.
   */
  id: PropTypes.string.isRequired,
  /**
   * The text displayed in the `<label>` element above the input.
   */
  label: PropTypes.string.isRequired,
  /**
   * Set to true to render a `<textarea>` instead.
   */
  multiline: PropTypes.bool,
  /**
   * The `name` attribute of the underlying element. Required.
   */
  name: PropTypes.string.isRequired,
  /**
   * Provide this unless using `multiline: true`.
   */
  type: PropTypes.oneOf(["email", "text"]), // TODO: expand options in future
};

TextField.defaultProps = {
  multiline: false,
  type: null,
};

export default TextField;
