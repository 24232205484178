import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";
import React from "react";

const SvgLinkedin = props => {
  const theme = useTheme();

  return (
    <Svg height="244" viewBox="0 0 244 244" width="244" {...props}>
      <path
        className="hoverable"
        d="M193.167 0H50.833C22.763 0 0 22.763 0 50.833v142.334C0 221.237 22.763 244 50.833 244h142.334c28.08 0 50.833-22.763 50.833-50.833V50.833C244 22.763 221.247 0 193.167 0zM81.333 193.167h-30.5V81.333h30.5v111.834zM66.083 68.442c-9.82 0-17.791-8.032-17.791-17.934 0-9.902 7.97-17.934 17.791-17.934 9.821 0 17.792 8.032 17.792 17.934 0 9.902-7.96 17.934-17.792 17.934zm137.25 124.725h-30.5v-56.974c0-34.242-40.666-31.65-40.666 0v56.974h-30.5V81.333h30.5v17.945c14.192-26.291 71.166-28.233 71.166 25.172v68.717z"
        fill={theme.colors.fg}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default SvgLinkedin;
