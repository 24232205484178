import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";
import React from "react";

const SvgGitlab = props => {
  const theme = useTheme();

  return (
    <Svg height="249" viewBox="0 0 269 249" width="269" {...props}>
      <path
        className="hoverable"
        d="M268.2 139.475l-14.109-43.399c-.075-.4-.19-.79-.341-1.167L225.443 7.682A11.254 11.254 0 00214.7 0a11.064 11.064 0 00-10.618 7.746L177.1 90.865H91.943l-27.02-83.12A11.064 11.064 0 0054.351 0h-.064a11.285 11.285 0 00-10.713 7.754l-28.33 87.33c0 .079-.063.142-.087.222L.795 139.483a16.128 16.128 0 005.848 18.041l124.097 90.269a6.343 6.343 0 007.5-.04l124.12-90.229a16.12 16.12 0 005.84-18.05zM82.706 103.623l34.591 106.578L34.26 103.623h48.447zm69.04 106.602l33.17-102.177 1.429-4.425h48.486l-75.126 96.346-7.96 10.256zM214.81 15.928l24.306 74.937h-48.653l24.347-74.937zm-41.829 87.648l-24.1 74.245-14.38 44.217-38.424-118.462h76.904zM54.233 15.928l24.354 74.937H29.966l24.267-74.937zM14.11 147.244a3.426 3.426 0 01-1.237-3.837l10.665-32.809 78.221 100.39-87.649-63.744zm240.79 0l-87.656 63.72.293-.381 77.928-99.985 10.665 32.793a3.426 3.426 0 01-1.23 3.845v.008z"
        fill={theme.colors.fg}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default SvgGitlab;
