import css from "@styled-system/css";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Infographic from "./Infographic";
import Text from "./Text";
import Button from "./Button";

const Project = styled(Text)`
  line-height: 1;
  letter-spacing: 0.25px;

  ${css({
    fontWeight: "bold",
  })}
`;

const BigNumber = styled(Text)`
  letter-spacing: -0.03em;
  line-height: 1;
  margin-top: -0.01em;
  text-transform: uppercase;

  ${css({
    color: "accent",
    fontFamily: "condensed",
    fontSize: ["6.5rem", "8rem"],
    fontWeight: "bold",
  })}
`;

const Stat = styled(Text)`
  line-height: 1;

  ${css({
    fontSize: ["1.1rem", "1.3rem"],
  })}
`;

const ClientInfographic = ({
  client,
  project,
  number,
  stat,
  linkAddress,
  background,
}) => (
  <Infographic background={background}>
    <div>
      <Text>{client}</Text>
      <Project>{project}</Project>
      <BigNumber>{number}</BigNumber>
      <Stat>{stat}</Stat>
    </div>
    <Button as={Link} to={linkAddress}>
      View case study
    </Button>
  </Infographic>
);

ClientInfographic.propTypes = {
  background: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  linkAddress: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  stat: PropTypes.string.isRequired,
};

export default ClientInfographic;
