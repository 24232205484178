import { get, system } from "styled-system";

const isNumber = n => typeof n === "number" && !Number.isNaN(n);
const getWidth = (n, scale) =>
  get(scale, n, !isNumber(n) || n > 1 ? n : `${n * 100}%`);

const config = {
  boxWidth: {
    property: "width",
    scale: "sizes",
    transform: getWidth,
  },
  boxHeight: {
    property: "height",
    scale: "sizes",
  },
  minWidth: {
    property: "minWidth",
    scale: "sizes",
  },
  minHeight: {
    property: "minHeight",
    scale: "sizes",
  },
  maxWidth: {
    property: "maxWidth",
    scale: "sizes",
  },
  maxHeight: {
    property: "maxHeight",
    scale: "sizes",
  },
  boxSize: {
    properties: ["width", "height"],
    scale: "sizes",
  },
  overflow: true,
  overflowX: true,
  overflowY: true,
  boxDisplay: {
    property: "display",
  },
  verticalAlign: true,
};

export const layout = system(config);
export default layout;
