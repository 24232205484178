import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const BlockSvg = styled.svg`
  display: block;
`;

/**
 * Internal component, useful for inline SVGs (inline meaning SVG code that's
 * directly in the DOM, not in the CSS/box model sense of the word!).
 *
 * - Sets `display: block`: usually a better default way to handle whitespace
 *   https://stackoverflow.com/questions/24626908/how-to-get-rid-of-extra-space-below-svg-in-div-element
 *
 * - Sets role="img" on the element: to encourage screen readers not to traverse
 *   the SVG code
 *   https://css-tricks.com/accessible-svgs/
 *
 * - Sets an accessible label using the <title> tag and the aria-labelledby
 *   attribute.
 *
 * - Sets focusable="false": maybe an SVG icon can be used inside a <button>
 *   but the SVG itself shouldn't be focusable. (This attribute affects IE only)
 *   https://stackoverflow.com/questions/29393144/how-to-prevent-svg-elements-from-gaining-focus-with-tabs-in-ie11
 */
const Svg = ({ alt, children, id, ...props }) => (
  <BlockSvg aria-labelledby={id} focusable="false" role="img" {...props}>
    <title id={id}>{alt}</title>
    {children}
  </BlockSvg>
);

Svg.propTypes = {
  alt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default Svg;
