import styled from "styled-components";
import { color, flexbox, space, border, position } from "styled-system";
import { layout } from "./styled-system";

const Box = styled.div`
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${border}
  ${position}
`;

export default Box;
