import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  display: inline-block;
  vertical-align: ${({ verticalAlign }) => verticalAlign};

  & + & {
    margin-left: ${({ theme, space }) => theme.rhythm(space)};
  }
`;

const Inline = ({ children, space, verticalAlign }) => {
  return (
    <List>
      {React.Children.map(children, child => (
        <ListItem space={space} verticalAlign={verticalAlign}>
          {child}
        </ListItem>
      ))}
    </List>
  );
};

Inline.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.number,
  verticalAlign: PropTypes.string,
};

Inline.defaultProps = {
  space: 0.25,
  verticalAlign: "baseline",
};

export default Inline;
