import { system } from "styled-system";

// A simple prop system that only exposes an "align" prop to control
// align-items in flexbox
const config = {
  align: {
    property: "align-items",
  },
};

export const align = system(config);
export default align;
