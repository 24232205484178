import PropTypes from "prop-types";
import css from "@styled-system/css";
import styled from "styled-components";
import Box from "./Box";

const getSpace = ({ theme, space }) => {
  return typeof space === "string" ? space : theme.rhythm(space);
};

const getMargins = props => {
  // Don't be tempted to refactor this destructuring into the function argument
  // list: the full `props` is passed to css() later on
  const { theme, space: spaceProp } = props;

  let responsiveSpaceProp = spaceProp;
  if (!Array.isArray(responsiveSpaceProp)) {
    responsiveSpaceProp = [null, ...theme.breakpoints].map(() => spaceProp);
  }

  // Split up the space array to figure out the different margins when
  // above/below the stacking breakpoint (assumed to be desktop).

  // Add 1 to compensate for the min/mobile breakpoint, which isn't explicitly
  // represented in the breakpoints array
  const stackingBreakpointIndex =
    theme.breakpoints.indexOf(theme.breakpoints.desktop) + 1;

  // The margin-top array should use the original space array, with all values
  // from the stacking breakpoint onwards set to 0 (because we want side-by-side
  // spacing from that point, not vertical spacing!).
  const marginTop = responsiveSpaceProp.map((s, i) => {
    if (i >= stackingBreakpointIndex) {
      return 0;
    }

    return getSpace({ theme, space: s });
  });

  // By the same logic, margin-left is a copy of the original space array, but
  // with zeros below the stacking breakpoint
  const marginLeft = responsiveSpaceProp.map((s, i) => {
    if (i < stackingBreakpointIndex) {
      return 0;
    }

    return getSpace({ theme, space: s });
  });

  // Now that we have separate arrays for margin-top and margin-left, we can
  // just feed that into styled-system's responsive CSS helper.
  return css({
    "& + &": {
      marginTop,
      marginLeft,
    },
  })(props);
};

const Column = styled(Box)`
  min-width: 0;
  ${getMargins}

  // Use passed in width above desktop breakpoint. Columns
  // are stacked below that, so custom widths make less
  // sense there.
  ${({ theme }) => theme.mediaQueries.desktop} {
    ${({ width }) => ({ width })}
  }
`;

Column.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  space: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

Column.defaultProps = {
  margin: null,
  space: null,
};

export default Column;
