import PropTypes from "prop-types";
import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";
import React from "react";

const SvgInstagram = ({ id, ...props }) => {
  const theme = useTheme();
  const maskId = `${id}-mask`;

  return (
    <Svg height="244" id={id} viewBox="0 0 244 244" width="244" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(.5 .5)">
          <mask fill={theme.colors.fg} id={maskId}>
            <path d="M0 .077h242.923V242.97H0z" />
          </mask>
          <path
            className="hoverable"
            d="M121.462.077c-32.987 0-37.124.14-50.079.73-12.928.59-21.757 2.644-29.483 5.646-7.988 3.104-14.761 7.257-21.514 14.01-6.752 6.753-10.906 13.526-14.01 21.513C3.375 49.702 1.322 58.532.732 71.46.14 84.415 0 88.55 0 121.539c0 32.986.14 37.123.73 50.078.59 12.928 2.644 21.757 5.647 29.483 3.103 7.988 7.257 14.761 14.009 21.514 6.753 6.752 13.526 10.906 21.514 14.01 7.726 3.002 16.555 5.055 29.483 5.645 12.955.591 17.092.731 50.079.731s37.123-.14 50.078-.73c12.928-.59 21.758-2.644 29.484-5.646 7.987-3.104 14.76-7.258 21.513-14.01 6.753-6.753 10.906-13.526 14.01-21.514 3.002-7.726 5.056-16.555 5.645-29.483.592-12.955.731-17.092.731-50.078 0-32.988-.14-37.124-.73-50.08-.59-12.927-2.644-21.757-5.646-29.483-3.104-7.987-7.257-14.76-14.01-21.513-6.753-6.753-13.526-10.906-21.513-14.01-7.726-3.002-16.556-5.056-29.484-5.645C158.585.216 154.45.077 121.462.077zm0 21.885c32.431 0 36.273.124 49.08.708 11.843.54 18.274 2.519 22.554 4.182 5.67 2.203 9.716 4.835 13.966 9.086 4.25 4.25 6.883 8.296 9.086 13.966 1.663 4.28 3.642 10.711 4.182 22.553.584 12.808.708 16.65.708 49.082 0 32.43-.124 36.273-.708 49.08-.54 11.843-2.519 18.274-4.182 22.554-2.203 5.67-4.835 9.716-9.086 13.966-4.25 4.25-8.296 6.882-13.966 9.086-4.28 1.663-10.711 3.642-22.553 4.182-12.806.584-16.647.708-49.081.708-32.434 0-36.275-.124-49.081-.708-11.843-.54-18.274-2.519-22.554-4.182-5.67-2.204-9.716-4.836-13.966-9.086s-6.882-8.297-9.086-13.966c-1.663-4.28-3.642-10.711-4.182-22.554-.584-12.807-.708-16.65-.708-49.08 0-32.432.124-36.274.708-49.082.54-11.842 2.519-18.273 4.182-22.553 2.204-5.67 4.836-9.716 9.086-13.966s8.297-6.883 13.966-9.086c4.28-1.663 10.711-3.642 22.554-4.182 12.807-.584 16.65-.708 49.08-.708z"
            fill={theme.colors.fg}
            mask={`url(#${maskId})`}
          />
        </g>
        <path
          className="hoverable"
          d="M121.5 162.746c-22.227 0-40.246-18.019-40.246-40.246 0-22.227 18.019-40.246 40.246-40.246 22.227 0 40.246 18.019 40.246 40.246 0 22.227-18.019 40.246-40.246 40.246zm0-102.246c-34.242 0-62 27.758-62 62 0 34.242 27.758 62 62 62 34.242 0 62-27.758 62-62 0-34.242-27.758-62-62-62zM201.5 58c0 8.008-6.492 14.5-14.5 14.5s-14.5-6.492-14.5-14.5 6.492-14.5 14.5-14.5 14.5 6.492 14.5 14.5"
          fill={theme.colors.fg}
        />
      </g>
    </Svg>
  );
};

SvgInstagram.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SvgInstagram;
