import Stack from "@dangerfarms/ui-system/Stack";
import Text from "@dangerfarms/ui-system/Text";
import PropTypes from "prop-types";
import React from "react";

const Label = ({ children, htmlFor, label, ...props }) => (
  <Text as="label" htmlFor={htmlFor} textSize="small" weight="news" {...props}>
    <Stack space={0.5}>
      <span>{label}</span>
      {children}
    </Stack>
  </Text>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Label;
