import css from "@styled-system/css";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color, variant as styledSystemVariant } from "styled-system";
import { typography, layout } from "./styled-system";

const standardFontSize = { fontSize: [3, 4] };

const getVariant = ({ textSize, theme, variant }) => {
  const variantFunction = styledSystemVariant({
    variants: {
      standard: {
        ...standardFontSize,
        fontWeight: "regular",
      },
      insane: {
        fontSize: [6, 8],
        fontWeight: "bold",
        lineHeight: 1.08,
      },
      epic: {
        fontSize: ["2.2rem", 7],
        fontWeight: "bold",
        lineHeight: 1.05,
      },
      heading: {
        fontSize: [5, 6],
        fontWeight: "bold",
        lineHeight: 1.1,
      },
      italic: {
        ...standardFontSize,
        fontStyle: "italic",
        fontWeight: "regular",
      },
      lede: {
        fontSize: [4, 5],
        fontWeight: "regular",
      },
      smallcaps: {
        fontSize: "smallcaps",
        fontWeight: "bold",
        letterSpacing: "0.04em",
        textTransform: "uppercase",
      },
      smallprint: {
        fontSize: [1, 2],
        fontWeight: "regular",
      },
      subheading: {
        fontSize: [4, 5],
        fontWeight: "bold",
        lineHeight: 1.1,
      },
    },
  });

  // Call the variant function directly: would usually be called
  // by Styled Components to call it, but we want to tweak the
  // returned object first.
  const baseVariant = variantFunction({ theme, variant });

  // Remove variant fontSize if the textSize prop overrides it
  if (textSize) {
    delete baseVariant.fontSize;
    Object.values(theme.mediaQueries).forEach(mediaQuery => {
      if (baseVariant[mediaQuery]) {
        delete baseVariant[mediaQuery].fontSize;
      }
    });
  }

  return baseVariant;
};

/**
 * Pick a default HTML tag based on variant.
 */
const getAttributes = ({ as: asProp, variant: variantProp }) => {
  if (asProp) {
    return null;
  }

  let as = "p";
  if (variantProp === "heading" || variantProp === "epic") {
    as = "h2";
  } else if (variantProp === "subheading") {
    as = "h3";
  }

  return { as };
};

const Text = styled.p.attrs(getAttributes)`
  line-height: ${({ theme }) => theme.baseLineHeight};

  ${css({
    color: "fg",
    fontFamily: "standard",
  })}

  ${getVariant}
  ${color}
  ${typography}
  ${layout}
`;

Text.propTypes = {
  variant: PropTypes.oneOf([
    "insane",
    "epic",
    "heading",
    "italic",
    "lede",
    "standard",
    "smallcaps",
    "smallprint",
    "subheading",
  ]),
  weight: PropTypes.oneOf(["regular", "news", "bold", "300", "400", "700"]),
};

Text.defaultProps = {
  variant: "standard",
  weight: null,
};

export default Text;
