import styled from "styled-components";

const VerticalLine = styled.div`
  height: ${({ size }) => size || "70px"};
  border-left: ${({ theme }) => theme.colors.accent} solid 1px;
`;

const HorizontalLine = styled.div`
  width: ${({ size }) => size || "70px"};
  border-top: ${({ theme }) => theme.colors.accent} solid 1px;
`;

export { HorizontalLine, VerticalLine };
