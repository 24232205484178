import PropTypes from "prop-types";
import React from "react";
import Box from "./Box";

const Columns = ({ align, children, justify, space, wrap }) => {
  // Pass the `space` prop to each child
  const columns = React.Children.map(children, element =>
    React.cloneElement(element, { space }),
  );

  return (
    <Box
      alignItems={align}
      boxDisplay="flex"
      boxWidth="100%"
      flexDirection={["column", "row"]}
      flexWrap={wrap}
      justifyContent={justify}
    >
      {columns}
    </Box>
  );
};

Columns.propTypes = {
  align: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  children: PropTypes.node.isRequired,
  justify: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  space: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  wrap: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

Columns.defaultProps = {
  align: "stretch",
  justify: "initial",
  space: 1,
  wrap: "nowrap",
};

export default Columns;
