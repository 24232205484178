import React from "react";
import PropTypes from "prop-types";
import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";

const CloseIcon = ({ size, ...props }) => {
  const theme = useTheme();

  return (
    <Svg height={size} viewBox="0 0 18 18" width={size} {...props}>
      <path
        className="hoverable"
        d="M16.323157 0L18 1.625088 10.648 9 18 16.374912 16.323157 18 8.999 10.653 1.676843 18 0 16.374912 7.351 9 0 1.625088 1.676843 0 9 7.346 16.323157 0z"
        fill={theme.colors.fg}
        fillRule="evenodd"
      />
    </Svg>
  );
};

CloseIcon.propTypes = {
  size: PropTypes.number,
};

CloseIcon.defaultProps = {
  size: 25,
};

export default CloseIcon;
