import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";
import React from "react";

const SvgTwitter = props => {
  const theme = useTheme();

  return (
    <Svg height="225" viewBox="0 0 276 225" width="276" {...props}>
      <path
        className="hoverable"
        d="M86.699 224.5c104.149 0 161.138-86.205 161.138-160.835 0-2.425 0-4.85-.11-7.275C258.77 48.453 268.378 38.42 276 27.067c-10.16 4.52-21.095 7.496-32.581 8.93 11.707-6.946 20.653-18.08 24.96-31.308-10.934 6.504-23.082 11.134-36.004 13.67C221.993 7.334 207.304.5 191.068.5c-31.255 0-56.657 25.354-56.657 56.551 0 4.41.552 8.709 1.435 12.898-47.049-2.315-88.797-24.914-116.74-59.087-4.859 8.378-7.62 18.079-7.62 28.441 0 19.622 10.05 36.93 25.181 47.071a57.367 57.367 0 01-25.623-7.055v.772c0 27.338 19.549 50.267 45.393 55.448-4.75 1.323-9.72 1.985-14.91 1.985a55.78 55.78 0 01-10.603-.993c7.18 22.489 28.164 38.804 52.903 39.245-19.438 15.212-43.846 24.252-70.353 24.252-4.528 0-9.056-.22-13.474-.772C24.96 215.13 54.78 224.5 86.699 224.5"
        fill={theme.colors.fg}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default SvgTwitter;
