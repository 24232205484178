import styled from "styled-components";

const PlainButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export default PlainButton;
