import css from "@styled-system/css";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Pre = styled.pre`
  width: 100%;
  overflow: auto;

  ${css({
    backgroundColor: "fg",
    color: "bg",
    padding: 3,
  })};
`;

const CodeBlock = ({ children }) => {
  return (
    <Pre>
      <code>{children}</code>
    </Pre>
  );
};

CodeBlock.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CodeBlock;
