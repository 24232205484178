import { rhythm } from "./rhythm";

const baseLineHeight = 1.5;

const theme = {
  baseLineHeight,
  baseFontSize: 19,
  radii: [0, 30],
  breakpoints: ["800px"],
  contentWidth: ["1200px", "1350px"],
  shadows: {
    large: "3px 6px 12px 0 rgba(0, 0, 0, 0.2)",
    mega: "0 0 25px rgba(0, 0, 0, 0.25)",
  },
  // TODO: document colors
  // TODO: is there a way to get autocomplete when using styled-system props?
  colors: {
    accent: "#0190ff",
    accentBright: ["#49a9ff"],
    bg: "white",
    paleGrey: "#f4f6f7",
    barelyGrey: "#fafbfb",
    fg: "black",
  },
  fonts: {
    condensed: "Barlow Condensed, sans-serif",
    standard: "FF Clan, sans-serif",
  },
  fontWeights: {
    bold: 700,
    news: 400,
    regular: 300,
  },
  fontSizes: [
    0,
    "0.75rem",
    "0.8rem",
    "0.9rem",
    "1rem",
    "1.2rem",
    "2.5rem",
    "4rem",
    "5.5rem",
  ],
  rhythm(n = 1) {
    return rhythm(baseLineHeight, n);
  },
  sizes: {
    headerHeight: 100,
  },
  space: [0, 0.25, 0.5, 1, 1.5, 2, 3, 4, 6, 8].map(n =>
    rhythm(baseLineHeight, n),
  ),
  zIndices: {
    popup: 50,
  },
};

// Aliases (https://styled-system.com/guides/array-scales#aliases)
theme.breakpoints.desktop = theme.breakpoints[0];
theme.fontSizes.smallcaps = theme.fontSizes[1];
theme.fontSizes.small = theme.fontSizes[2];
theme.fontSizes.standardMobile = theme.fontSizes[3];
theme.fontSizes.standard = theme.fontSizes[4];
theme.fontSizes.lede = theme.fontSizes[5];
theme.fontSizes.subheading = theme.fontSizes[6];
theme.fontSizes.heading = theme.fontSizes[7];
theme.fontSizes.epic = theme.fontSizes[8];
theme.space.gutter = "15px";

// Add a wide breakpoint.
// The parseInt trick turns a string like "10px" into a raw number.
const wide = parseInt(theme.contentWidth[1], 10);
const gutter = parseInt(theme.space.gutter, 10);
theme.breakpoints.push(`${wide + gutter}px`);
theme.breakpoints.wide = theme.breakpoints[theme.breakpoints.length - 1];

// Media query shortcuts (https://styled-system.com/theme-specification#media-queries)
theme.mediaQueries = {
  desktop: `@media screen and (min-width: ${theme.breakpoints[0]})`,
  wide: `@media screen and (min-width: ${theme.breakpoints[1]})`,
};

export { theme };
