import React from "react";
import PropTypes from "prop-types";
import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";

const OpenIcon = ({ size, ...props }) => {
  const theme = useTheme();

  const uniqueID = Math.random();

  return (
    <Svg
      alt="Open button"
      height={size}
      id={`openIcon-${uniqueID}`}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      {...props}
    >
      <defs>
        <path
          d="M0 12v1h25v-1H0zM13 0h-1v25h1V0z"
          id={`openIconPathA-${uniqueID}`}
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(8 7)">
        <mask fill={theme.colors.accent} id={`openIconPathB-${uniqueID}`}>
          <use xlinkHref={`#openIconPathA-${uniqueID}`} />
        </mask>
        <use
          fill={theme.colors.accent}
          fillRule="nonzero"
          xlinkHref={`#openIconPathA-${uniqueID}`}
        />
        <g mask={`url(#openIconPathB-${uniqueID})`}>
          <path d="M-8-7h40v40H-8z" fill={theme.colors.bg} />
        </g>
      </g>
    </Svg>
  );
};

OpenIcon.propTypes = {
  size: PropTypes.number,
};

OpenIcon.defaultProps = {
  size: 40,
};

export default OpenIcon;
