import styled from "styled-components";
import Box from "./Box";

const ContentBlock = styled(Box)`
  margin: 0 auto;
  max-width: ${({ theme, wide }) =>
    wide ? theme.contentWidth[1] : theme.contentWidth[0]};
  width: 100%;
`;

export default ContentBlock;
