import css from "@styled-system/css";
import styled from "styled-components";
import Text from "./Text";

const ListItem = styled(Text).attrs({ as: "li" })`
  &::before {
    content: "■ ";

    ${css({
      color: "accent",
      marginRight: 2,
    })}
  }
`;

export default ListItem;
