import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";
import PropTypes from "prop-types";
import React from "react";

const EmailIcon = ({ id, ...props }) => {
  const theme = useTheme();

  return (
    <Svg
      alt="Email"
      height={10}
      id={id}
      viewBox="0 0 15 10"
      width={15}
      {...props}
    >
      <path
        className="hoverable"
        d="M.371 0C.168.024-.002.228 0 .442v9.13c.003.223.196.425.41.428h14.18c.214-.003.407-.205.41-.428V.442c0-.226-.194-.439-.41-.442H.37zm.833.876h12.592L7.506 5.36 1.205.876zm-.377.83l6.4 4.578a.43.43 0 00.547 0l6.4-4.579v7.432H.826V1.705z"
        fill={theme.colors.accent}
      />
    </Svg>
  );
};

EmailIcon.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EmailIcon;
