/**
 * Prismic's GraphQL API returns a different format for internal links, it looks
 * like this:
 *
 * {
 *   "_linkType": "Link.document",
 *   "_meta": {
 *     "uid": "case-studies",
 *     "type": "case_studies_list",
 *   }
 * }
 *
 *  Whereas links encountered in Rich Text field queries look more like this:
 *
 * {
 *   "link_type": "Document",
 *   "type": "case_studies_list",
 *   "uid": "cookies"
 * }
 *
 *  Unfortunately the two formats don't contain all of the same data :( but we
 *  can at least make the shapes match for those key properties (link_type,
 *  type and uid).
 */

const normalizePrismicLink = link => {
  const isRawLinkField = "_linkType" in link;
  const isRichTextLinkField = "link_type" in link;

  // Rich text links are already correct
  if (isRichTextLinkField) {
    return link;
  }

  if (isRawLinkField) {
    const unprefixedLinkType = link._linkType.replace("Link.", "");
    const normalizedLinkType =
      unprefixedLinkType.charAt(0).toUpperCase() + unprefixedLinkType.slice(1);

    const normalizedLink = {
      link_type: normalizedLinkType,
    };

    if (normalizedLinkType === "Document") {
      normalizedLink.uid = link._meta.uid;
      normalizedLink.type = link._meta.type;
    } else if (normalizedLinkType === "Web") {
      normalizedLink.url = link.url;
    } else {
      throw new Error(`Unhandled link type ${link._linkType}`);
    }

    return normalizedLink;
  }

  // If we reach here, something's up with the passed in link
  throw new Error(`Unexpected link format: ${link}`);
};

/**
 * This odd link format is a workaround for a limitation in Prismic.
 * You can't link directly to a heading in a rich text block, and
 * it autoformats manual anchor strings with an https prefix.
 *
 * Use with getHashFragment to strip the https:// prefix.
 */

const isHash = link => {
  return link.link_type === "Web" && link.url.startsWith("https://#");
};

/**
 * Strip the unwanted https:// prefix from links intended as fragment/hash
 * anchors (ie. linking elsewhere within the same page)
 *
 * Consider checking the link with isHash first.
 */
const getHashFragment = link => {
  return link.url.replace("https://", "");
};

export { getHashFragment, isHash, normalizePrismicLink };
