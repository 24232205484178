import styled from "styled-components";
import css from "@styled-system/css";

const Input = styled.input`
  border: 0;
  border-bottom: solid 1px hsla(0, 0%, 0%, 0.25);
  border-radius: 0; // iOS Safari sets a default one!
  width: 100%;

  ${css({
    backgroundColor: "paleGrey",
    fontFamily: "standard",
    fontSize: "0.85rem",
    fontWeight: "news",
    paddingX: 2,
    paddingY: 2,
  })}

  &:focus {
    outline: none;

    ${css({
      borderBottomColor: "accent",
    })}
  }
`;

export default Input;
