import Svg from "@dangerfarms/ui-system/Svg";
import { useTheme } from "@dangerfarms/ui-system/useTheme";
import React from "react";

const SvgGithub = props => {
  const theme = useTheme();

  return (
    <Svg height="238" viewBox="0 0 244 238" width="244" {...props}>
      <path
        className="hoverable"
        d="M121.989 0C54.629 0 0 54.626 0 122.015c0 53.907 34.953 99.635 83.433 115.769 6.104 1.123 8.328-2.644 8.328-5.88 0-2.899-.105-10.569-.165-20.748-33.934 7.37-41.094-16.358-41.094-16.358-5.55-14.097-13.549-17.85-13.549-17.85-11.077-7.565.839-7.415.839-7.415 12.245.861 18.686 12.576 18.686 12.576 10.882 18.643 28.558 13.258 35.508 10.134 1.108-7.88 4.261-13.257 7.744-16.306-27.09-3.078-55.572-13.55-55.572-60.303 0-13.318 4.756-24.216 12.56-32.74-1.258-3.086-5.445-15.497 1.198-32.29 0 0 10.238-3.28 33.546 12.508 9.728-2.711 20.169-4.06 30.542-4.112 10.365.053 20.798 1.4 30.542 4.112 23.292-15.789 33.515-12.508 33.515-12.508 6.658 16.793 2.472 29.204 1.221 32.29 7.82 8.524 12.538 19.422 12.538 32.74 0 46.873-28.528 57.187-55.7 60.206 4.374 3.767 8.276 11.213 8.276 22.59 0 16.314-.15 29.474-.15 33.474 0 3.266 2.202 7.063 8.389 5.872C209.077 221.605 244 175.907 244 122.016 244 54.625 189.372 0 121.989 0"
        fill={theme.colors.fg}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default SvgGithub;
