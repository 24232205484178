import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import ffClanStdBoldWoff from "./fonts/ff-clan-std-bold.woff";
import ffClanStdBoldWoff2 from "./fonts/ff-clan-std-bold.woff2";
import ffClanStdRegularWoff from "./fonts/ff-clan-std-regular.woff";
import ffClanStdRegularWoff2 from "./fonts/ff-clan-std-regular.woff2";
import ffClanStdNewsWoff from "./fonts/ff-clan-std-news.woff";
import ffClanStdNewsWoff2 from "./fonts/ff-clan-std-news.woff2";

const FFClanFonts = createGlobalStyle`
  @font-face {
    font-display: block;
    font-family: "FF Clan";
    font-weight: 300;
    src: url("${ffClanStdRegularWoff2}") format("woff2"),
         url("${ffClanStdRegularWoff}") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "FF Clan";
    font-weight: 400;
    src: url("${ffClanStdNewsWoff2}") format("woff2"),
         url("${ffClanStdNewsWoff}") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "FF Clan";
    font-weight: 700;
    src: url("${ffClanStdBoldWoff2}") format("woff2"),
         url("${ffClanStdBoldWoff}") format("woff");
  }
`;

/**
 * Adds the required fonts to the HTML <head>.
 *
 * TODO: Storybook contains a workaround that duplicates the font.
 */
const Fonts = () => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Barlow+Condensed:600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <FFClanFonts />
  </>
);

export default Fonts;
