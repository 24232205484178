import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider } from "styled-components";

const darkTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    bg: theme.colors.fg,
    fg: theme.colors.bg,
    accent: theme.colors.accentBright[0],
  },
});

const accentTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    bg: theme.colors.accent,
    fg: theme.colors.bg,
    accent: theme.colors.bg,
  },
});

const getTheme = mode => {
  switch (mode) {
    case "dark":
      return darkTheme;
    case "accent":
      return accentTheme;
    default:
      throw new Error(
        `Invalid color mode ${mode} passed to ColorModeProvider.`,
      );
  }
};

/**
 * Wrap around a tree of styled components to change the colors. This can be
 * used to create a "dark mode", or other color modes.
 *
 * See: https://styled-components.com/docs/advanced#function-themes
 */
const ColorModeProvider = ({ mode, ...props }) => (
  <ThemeProvider theme={getTheme(mode)} {...props} />
);

ColorModeProvider.propTypes = {
  mode: PropTypes.oneOf(["dark", "accent"]).isRequired,
};

export default ColorModeProvider;
