import css from "@styled-system/css";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { color } from "styled-system";
import ContentBlock from "./ContentBlock";

const SectionBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 750px;

  ${css({
    paddingX: "gutter",
    paddingY: 6,
  })};

  ${color}
`;

const Section = ({ children, ...props }) => {
  return (
    <SectionBox {...props}>
      <ContentBlock>{children}</ContentBlock>
    </SectionBox>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Section;
