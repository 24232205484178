import { Stack, Text } from "@dangerfarms/ui-system";
import css from "@styled-system/css";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import slugify from "./slugify";

const List = styled(Stack).attrs({ as: "ol", space: 0.5 })`
  list-style: none;
`;

const Link = styled.a`
  text-decoration: none;
`;

const LinkText = styled(Text).attrs({ variant: "smallcaps" })`
  ${Link}:hover > & {
    ${css({
      color: "accent",
    })}
  }
`;

// Avoiding documenting the `of` prop, because it's too complex to do usefully.
// It's a Prismic rich text array returned by a GraphQL query.
/* eslint-disable react/prop-types */
const TableOfContents = ({ ariaLabel, of }) => {
  // TODO: for now, this just lists the heading2 elements. In future,
  //       we could nest h3s too.
  //
  //       We don't need to consider h1s. There's only one per page (assuming
  //       a valid, accessible HTML document). Including it would just nest
  //       everything by one level (pointlessly).
  const sectionHeadings = of
    .filter(content => content.type === "heading2")
    .map(content => content.text);

  return (
    <nav aria-label={ariaLabel}>
      <Stack>
        <Text as="h2" variant="subheading">
          Table of Contents
        </Text>
        <List>
          {sectionHeadings.map(heading => {
            // Note the assumption here: that this is how the header IDs
            // are formed.
            const id = slugify(heading);

            return (
              <li key={id}>
                <Link href={`#${id}`}>
                  <LinkText>{heading}</LinkText>
                </Link>
              </li>
            );
          })}
        </List>
      </Stack>
    </nav>
  );
};
/* eslint-enable */

TableOfContents.propTypes = {
  // When using this component, multiple <nav>s are rendered onscreen (since
  // there's always a global <nav> in the header too. So make sure you provide
  // a globally unique label.
  ariaLabel: PropTypes.string.isRequired,
};

export default TableOfContents;
