import css from "@styled-system/css";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Infographic from "./Infographic";
import Text from "./Text";

const GiantNumber = styled(Text)`
  ${css({
    fontSize: ["12rem", "13rem"],
  })}
  letter-spacing: -0.03em;
  line-height: 1;
  margin-top: -0.01em;
  text-transform: uppercase;

  ${css({
    fontFamily: "condensed",
    fontWeight: "bold",
  })}
`;

const StatText = styled(Text)`
  ${css({
    fontSize: ["2rem", "2.4rem"],
  })}
  line-height: 1;
`;

const StatHighlight = styled(StatText)`
  ${css({
    fontWeight: "news",
  })}
`;

const NumberInfographic = ({ background, number, stat, statHighlight }) => (
  <Infographic background={background} backgroundSize="400px" space={0}>
    <GiantNumber>{number}</GiantNumber>
    <StatText>{stat}</StatText>
    {statHighlight && <StatHighlight>{statHighlight}</StatHighlight>}
  </Infographic>
);

NumberInfographic.propTypes = {
  background: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  stat: PropTypes.string.isRequired,
  statHighlight: PropTypes.string,
};

NumberInfographic.defaultProps = {
  statHighlight: null,
};

export default NumberInfographic;
